<template>
  <div class="print-box">
    <div class="print-n-box">
      <div id="printBox">
        <div
          style="page-break-before:always"
          class="print-mb100"
          v-for="(detailInfo,index) in batchPrintData"
          :key="index">
          <div style="font-size: 24px;font-weight: 600;border-bottom: 1px solid #ccc;padding-bottom: 15px;line-height: 60px;">
            <span v-if="type === 'pickList'">{{ $t('key1000180') }}</span><span>{{ detailInfo.pickingGoodsNo }}</span>
            <span v-if="type === 'checkList'">{{ $t('key1003719') }}</span><span>{{ detailInfo.inventoryCheckNo }}</span> <font
            id="printSkuBarCode"
            style="font-family: IDAutomationC128S;margin-left: 10px"
            v-html="detailInfo.barcode"></font>
          </div>
          <div style="padding: 20px 0;border-bottom: 1px solid #ccc">
            <div>
              <span v-if="type === 'pickList'"><span class="textTitle">{{ $t('key1000181') }}</span><span>{{ detailInfo.warehouseName }}</span></span>
              <span v-if="type === 'checkList'"><span class="textTitle">{{ $t('key1003720') }}</span><span>{{ detailInfo.warehouseName }}</span></span>
            </div>
            <div>
            <span v-if="type === 'pickList'"><span class="textTitle ab">{{ $t('key1001134') }}</span><span>{{
                detailInfo.carrierMethodNameList.length > 1
                  ? $t('key1000199') +
                  detailInfo.carrierMethodNameList[0] +
                  $t('key1000197')
                  : detailInfo.carrierMethodNameList[0]
              }}</span></span>
            </div>
            <div>
            <span v-if="type === 'pickList'">
              <span class="textTitle">{{ $t('key1003721') }}</span>
              <span>{{ detailInfo.remark }}</span></span>
            </div>
            <div>
            <span
              v-if="type === 'pickList'"
              style="display: inline-block;width: 300px;white-space: nowrap"><span class="textTitle ab">{{ $t('key1003110') }}</span><span>{{
                detailInfo.carrierNameList.length > 1
                  ? $t('key1000199') +
                  '，' +
                  detailInfo.carrierNameList[0] +
                  $t('key1000197')
                  : detailInfo.carrierNameList[0]
              }}</span></span>
            </div>
            <div>
            <span
              style="display: inline-block;width: 250px"
              v-if="type === 'pickList'"><span class="textTitle">{{ $t('key1000183') }}</span><span>{{
                detailInfo.packageGoodsType === 'MM'
                  ? $t('key1000178')
                  : $t('key1000179')
              }}</span></span> <span
              v-if="type === 'pickList'"
              style="display: inline-block;width: 180px"><span class="textTitle ab">{{ $t('key1000184') }}</span><span>{{ detailInfo.pickingNumber }}</span></span>
              <span style="display: inline-block;width: 250px" v-if="type === 'checkList'">
              <span class="textTitle">{{ $t('key1003722') }}</span><span>{{
                  detailInfo.checkStatus === '0'
                    ? $t('key1003717')
                    : detailInfo.checkStatus === '1'
                      ? $t('key1003718')
                      : detailInfo.checkStatus === '2'
                        ? $t('key1003391')
                        : ''
                }}</span>
            </span>
              <span v-if="type === 'checkList'"><span class="textTitle ab">{{ $t('key1000870') }}</span><span>{{ $uDate.getDataToLocalTime(detailInfo.createdTime) }}</span></span>
            </div>
            <div>
              <span
                style="display: inline-block;width: 250px"
                v-if="type === 'pickList'"><span class="textTitle">{{ $t('key1000870') }}</span><span>{{ $uDate.getDataToLocalTime(detailInfo.createdTime, 'fulltime') }}</span></span>
              <span
                v-if="type === 'pickList'"
                style="display: inline-block;width: 180px;white-space: nowrap"><span class="textTitle">{{ $t('key1000186') }}</span><span>{{ detailInfo.goodsQuantityNumber }}</span></span>
              <span style="display: inline-block;width: 250px" v-if="type === 'checkList'">
              <span
                style="display: inline-block;width: 250px"
                v-if="type === 'checkList'"><span class="textTitle">{{ $t('key1003723') }}</span><span>{{ detailInfo.beginDate ? 'Y' : 'N' }}</span></span>
              <span v-if="type === 'checkList'"><span class="textTitle">{{ $t('key1003724') }}</span><span>{{
                  detailInfo.blindFlag === '1' ? 'Y' : 'N'
                }}</span></span>
            </span>
            </div>
            <div>
            <span
              style="display: inline-block;width: 250px"
              v-if="type === 'pickList'"><span class="textTitle">{{ $t('key1000187') }}</span><span>{{
                detailInfo.pickingGoodsUser ? detailInfo.pickingGoodsUser : ''
              }}</span></span>
              <span v-if="type === 'pickList'"><span class="textTitle">{{ $t('key1000188') }}</span><span>{{ detailInfo.goodsSkuNumber }}</span></span>
              <span
                style="display: inline-block;width: 250px"
                v-if="type === 'checkList' && detailInfo.checkStatus !== '0'"><span class="textTitle">{{ $t('key1003725') }}</span><span>{{
                  detailInfo.checkUserName ? detailInfo.checkUserName : ''
                }}</span></span>
            </div>
          </div>
          <table width="100%">
            <thead style="background-color: #ccc">
            <tr>
              <th style="padding: 6px 0" v-show="filterColumns.includes('goodsUrl')">
                {{ $t('key1000160') }}
              </th>
              <th style="padding: 6px 0" v-show="filterColumns.includes('warehouseBlockName')">
                {{ $t('key1000189') }}
              </th>
              <th style="padding: 6px 0" v-show="filterColumns.includes('warehouseLocationName')">
                {{ $t('key1000190') }}
              </th>
              <th style="padding: 6px 0" v-show="filterColumns.includes('spu')">SPU</th>
              <th style="padding: 6px 0" v-show="filterColumns.includes('goodsSku')">SKU</th>
              <th style="padding: 6px 0" v-show="filterColumns.includes('ymsSpu')">{{ configCharacters + 'SPU' }}</th>
              <th style="padding: 6px 0" v-show="filterColumns.includes('ymsSku')">{{ configCharacters + 'SKU' }}</th>
              <th style="padding: 6px 0" v-if="type === 'pickList' && filterColumns.includes('expectedNumber')">
                {{ $t('key1000191') }}
              </th>
              <th style="padding: 6px 0" v-show="filterColumns.includes('goodsCnDesc')">
                {{ $t('key1000192') }}
              </th>

              <th style="padding: 6px 0" v-show="filterColumns.includes('goodsAttributes')">
                {{ $t('key1000193') }}
              </th>
              <th style="padding: 6px 0" v-show="filterColumns.includes('receiptBatchNo')">
                {{ $t('key1000195') }}
              </th>
              <th style="padding: 6px 0" v-if="type === 'checkList' && filterColumns.includes('oriTotalNumber')">
                {{ $t('key1003726') }}
              </th>
              <th style="padding: 6px 0" v-if="type === 'checkList' && filterColumns.includes('countingQuantity')">
                {{ $t('key1003727') }}
              </th>
              <th style="padding: 6px 0" v-if="type === 'checkList' && filterColumns.includes('gainsAndLosses')">
                {{ $t('key1003728') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(val, i) in detailInfo.totalItem" :key="i">
              <td align="center" style="white-space: nowrap" v-show="filterColumns.includes('goodsUrl')">
                <img :src="setImgPath(val.goodsUrl)" crossOrigin="anonymous" width="80" height="80" style="margin: 10px"/>
              </td>
              <td
                align="center"
                style="white-space: nowrap"
                v-show="filterColumns.includes('warehouseBlockName')">{{ val.warehouseBlockName }}
              </td>
              <td align="center" style="white-space: nowrap" v-show="filterColumns.includes('warehouseLocationName')">
                {{ val.warehouseLocationName }}
              </td>

              <td align="center" style="word-break: break-all;" v-show="filterColumns.includes('spu')">
                {{ val.spu }}
              </td>
              <td align="center" style="word-break: break-all;" v-show="filterColumns.includes('goodsSku')">
                {{ val.goodsSku }}
              </td>
              <td align="center" style="word-break: break-all;" v-show="filterColumns.includes('ymsSpu')">
                {{ val.ymsSpu }}
              </td>
              <td align="center" style="word-break: break-all;" v-show="filterColumns.includes('ymsSku')">
                {{ val.ymsSku }}
              </td>
              <td align="center" v-if="type === 'pickList' && filterColumns.includes('expectedNumber')">
                {{ val.expectedNumber }}
              </td>

              <td align="left" style="word-break: break-all;" v-show="filterColumns.includes('goodsCnDesc')">
                {{ val.goodsCnDesc }}
              </td>
              <td align="left" style="word-break: break-all;" v-show="filterColumns.includes('goodsAttributes')">
                {{ val.goodsAttributes ? val.goodsAttributes : '' }}
              </td>
              <td align="left" style="word-break: break-all;" v-show="filterColumns.includes('goodsEnDesc')">
                {{ val.goodsEnDesc }}
              </td>
              <td align="center" style="word-break: break-all;" v-show="filterColumns.includes('receiptBatchNo')">
                {{ val.receiptBatchNo }}
              </td>
              <td align="center" v-if="type === 'checkList' && filterColumns.includes('oriTotalNumber')">
                {{ detailInfo.blindFlag === '1' ? '*' : val.oriTotalNumber }}
              </td>
              <td align="center" v-if="type === 'checkList' && filterColumns.includes('countingQuantity')"></td>
              <td align="center" v-if="type === 'checkList' && filterColumns.includes('gainsAndLosses')"></td>
            </tr>
            </tbody>
          </table>
          <div class="clear">
            <div class="fr" style="margin-top: 50px">
              <span style="margin-right: 100px;font-size: 14px">{{ $t('key1002480') }} {{ $t('key1002481') }}</span><span style="margin-right: 100px;font-size: 14px">{{ $t('key1002482') }} {{ $t('key1002483') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="position: absolute;right: -125px;top:0" class="print-none">
        <div style="margin-bottom: 10px">
          <Button type="primary" @click="print">{{ $t('key1000858') }}</Button>
        </div>
        <div style="margin-bottom: 10px">
          <Button type="primary" @click="download">{{ $t('key1000373') }}</Button>
        </div>
        <div style="margin-bottom: 10px">
          <Button type="primary" @click="cancelPrint">{{ $t('key1002484') }}</Button>
        </div>
        <div style="height: 35px;position: relative">
          <local-filterColumns
            :columns="columns"
            :filterName="'printPickList'"
            @setTableColumns="setTableColumns"></local-filterColumns>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

// 打印盘点单与拣货单共用模块
import api from '@/api/';
import common from '@/components/mixin/common_mixin';
import printMixin from '@/components/mixin/print_mixin';
import pdf from '@/utils/pdf';
import {setConfigCharacters} from "@/utils/common";

export default {
  // type === 'checkList'为盘点单
  mixins: [common, printMixin],
  data() {
    let v = this;
    let columns1 = [
      {
        title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000160'),
        key: 'goodsUrl'
      }, {
        title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000189'),
        key: 'warehouseBlockName',
        filterHide: true
      }, {
        title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000190'),
        key: 'warehouseLocationName',
        filterHide: true
      }, {
        title: 'SPU',
        key: 'spu'
      }, {
        title: 'SKU',
        key: 'goodsSku'
      }, {
        title: `${setConfigCharacters}SPU`,
        key: 'ymsSpu'
      }, {
        title: `${setConfigCharacters}SKU`,
        key: 'ymsSku'
      }, {
        title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000192'),
        key: 'goodsCnDesc'
      }, {
        title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000193'),
        key: 'goodsAttributes'
      }, {
        title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000195'),
        key: 'receiptBatchNo',
        filterHide: true
      }
    ];
    let columns2 = JSON.parse(JSON.stringify(columns1));
    let columns3 = JSON.parse(JSON.stringify(columns1));
    columns2.splice(2, 0, {
      title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000191'),
      key: 'expectedNumber'
    });
    columns3.push({
      title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003726'),
      key: 'blindFlag',
      render(h, params) {
        let data = v.detailInfo.blindFlag === '1' ? '*' : params.row.oriTotalNumber;
        return h('span', data);
      }
    }, {
      title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003727'),
      key: 'countingQuantity'
    }, {
      title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003728'),
      key: 'gainsAndLosses'
    });
    return {
      placeholderUrl: require('#@/static/images/placeholder.jpg'),
      localeUrl: window.location.origin
        ? window.location.origin
        : window.location.protocol + '//' + window.location.hostname + (window.location.port
        ? ':' + window.location.port
        : ''), // 兼容性写法
      totalItem: '',
      detailInfo: [],
      barCodeString: '',
      type: '',
      batchPrintData: [],
      columns: [],
      filterColumns: [],
      columns2: columns2,
      columns3: columns3
    };
  },
  computed: {
    prefixUrl() {
      return localStorage.getItem('imgUrlPrefix');
    }
  },
  created() {
    let obj = this.$route.query.data;
    this.type = this.$route.query.type;
    this.where = this.$route.query.where;
    this.goodsSkuOrGoodsCnDesc = this.$route.query.goodsSkuOrGoodsCnDesc;
    console.log(typeof obj);
    if (typeof obj === 'object') {
      window.localStorage.setItem('paramsDtl', JSON.stringify(obj));
    } else if (typeof JSON.parse(window.localStorage.getItem('paramsDtl')) === 'object' && JSON.parse(window.localStorage.getItem('paramsDtl')) !== null) {
      obj = JSON.parse(window.localStorage.getItem('paramsDtl'));
    }
    let paramsObj = null;
    if (this.type === 'pickList') {
      paramsObj = {
        isPage: '1',
        status: '1',
        pickingGoodsNos: obj.split(',')
      };
      this.columns = this.columns2;
    } else if (this.type === 'checkList') {
      paramsObj = {
        isPage: '1',
        status: '1',
        goodsSkuOrGoodsCnDesc: this.goodsSkuOrGoodsCnDesc,
        pickingGoodsNos: obj ? [obj] : null
      };
      this.columns = this.columns3;
    }
    // 盘点单需要的参数
    if (this.type === 'checkList') {
      if (typeof obj === 'object') {
        paramsObj.inventoryCheckNo = obj.inventoryCheckNo ? [obj.inventoryCheckNo] : null;
      } else if (typeof obj === 'string') {
        paramsObj.inventoryCheckNo = obj;
      }
      delete paramsObj.pickingGoodsNos;
    }
    this.$Spin.show();
    this.axios.post(this.type === 'checkList' ? api.get_scanDetailData : api.post_wmsPickingGoods_detail, paramsObj).then(res => {
      if (res.data.code === 0) {
        let arr = [];
        arr = res.data.datas;
        if (this.type === 'checkList') {
          this.totalItem = res.data.datas.inventoryCheckDetailResultPage.list;
        } else {
          arr.forEach(i => {
            if (paramsObj.status === '0') {
              i.totalItem = i.queryByPickingResultPage.list;
            } else {
              i.totalItem = i.queryByGoodsDetailResultPage.list;
            }
          });
        }
        let pickingGoodsNo = obj.split(',');
        this.axios.post(api.getBarCode, pickingGoodsNo).then(res => {
          this.$Spin.hide();
          if (res.data.code === 0) {
            arr.forEach(val1 => {
              res.data.datas.forEach(val2 => {
                let key = Object.getOwnPropertyNames(val2)[0];
                if (val1.pickingGoodsNo === key) {
                  let value = val2[key] ? val2[key] : '';
                  this.$set(val1, 'barcode', value);
                }
              });
            });
            this.batchPrintData = arr;
          }
        });
      }
    });
  },
  destroyed() {
    localStorage.removeItem('paramsDtl');
  },
  methods: {
    setTableColumns(data) {
      this.filterColumns = data.map(i => i.key);
    },
    print() {
      let obj = this.$route.query.data;
      this.axios.post(api.post_wmsPickingGoods_print, {
        pickingGoodsNos: obj.split(',')
      }).then(res => {
        if (res.data.code === 0) {
          this.$Message.success(res.data.message);
        }
      });
      window.print();
    },
    cancelPrint() {
      window.location.href = '#/wms/pickList';
    },
    changePrintStatus(obj) {
      let paramsObj = {
        pickingGoodsNos: [obj]
      };
      this.axios.post(api.post_wmsPickingGoods_print, paramsObj).then(res => {
        if (res.data.code === 0) {
          this.$Message.success(res.data.message);
        }
      });
    },
    download() {
      pdf('#printBox', 'web');
    }
  }
};
</script>
<style>
.iview-table-setWidth .ivu-table:before {
  height: 0;
}

.iview-table-setWidth .ivu-table:after {
  height: 0;
}

.print-mb100 {
  margin-bottom: 100px;
}

.print-n-box {
  width: 1000px;
  background-color: #fff;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

.print-box {
  background-color: #ccc;
  padding: 34px 56px;
  font-size: 16px;
}

#printBox {
  padding: 20px;
}

.iview-table-setWidth .ivu-table-overflowX {
  overflow: hidden;
}

.print-n-box .ivu-table-wrapper {
  border: 1px solid #999;
  border-bottom: 0;
}

.print-n-box .ivu-table td,
.print-n-box .ivu-table th {
  border-bottom: 1px solid #999;
}

@page {
  margin: 0;
  padding: 0;
}

@media print {
  html,
  body,
  #app {
    min-width: auto;
  }

  * {
    margin: 0;
    padding: 0;
    font-weight: bold;
  }

  .iview-table-setWidth .ivu-table-header table {
    width: 100% !important;
    table-layout: initial;
  }

  .iview-table-setWidth .ivu-table-body table {
    width: 100% !important;
    table-layout: initial;
  }

  .iview-table-setWidth .ivu-table:before {
    height: 0;
  }

  .iview-table-setWidth .ivu-table:after {
    height: 0;
  }

  .print-n-box .ivu-table-wrapper {
    border: 1px solid #999;
    border-bottom: 0;
  }

  .print-n-box .ivu-table td,
  .print-n-box .ivu-table th {
    border-bottom: 1px solid #999;
  }

  #printBox {
    padding: 0 !important;
  }

  .print-n-box {
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
    padding: 0 16px;
  }

  .print-box {
    width: 100%;
    font-size: 16px;
    background-color: #ccc;
    padding: 0;
  }

  .print-mb100 {
    margin-top: 5pt;
  }

  .print-none {
    display: none;
  }
}

.textTitle {
  font-size: 16px;
  width: 150px;
}

* {
  margin: 0;
  padding: 0;
}

.print-none .filterColumns {
  position: absolute;
  right: -18px;
  bottom: -4px;
  width: 100px;
  height: 32px;
}

</style>
<style scoped>
table {
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 0px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top: 1px solid #9a9a9a;
  border-left: 1px solid #9a9a9a;
}

.table-border {
  border: 1px solid #666;
  border-bottom: 0;
  border-right: 0;
}

table td,
table th {
  border-bottom: 1px solid #9a9a9a;
  border-right: 1px solid #9a9a9a;
}
</style>
